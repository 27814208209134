
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <aside className="sidebar">
      <ul>
        <li onClick={() => navigate('/')}>Home</li>
        <li onClick={() => navigate('/buy-vouchers')}>Buy Vouchers</li>
        <li onClick={() => navigate('/redeem-voucher')}>Redeem Voucher</li>
        <li onClick={() => navigate('/wifi-stats')}>WiFi Stats</li>
        <li onClick={() => navigate('/support')}>Support</li>
      </ul>
    </aside>
  );
};

export default Sidebar;
    